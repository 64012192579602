import styled from 'styled-components'
import { Button, P, Wrapper } from '@farewill/ui'

import ENV from 'config/environment'
import { hideModal } from 'state/actions'
import store from 'state/create-store'

const StyledWrapper = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
`

type CouplePlanModalConfig = {
  config: {
    confirmCreateLead: () => void
    secondaryCoveredIndividualName?: string
  }
}

const CouplePlanModal = ({ config }: CouplePlanModalConfig) => {
  const handleConfirmClick = async () => {
    await config.confirmCreateLead()
    store.dispatch(hideModal())
  }

  return (
    <Wrapper>
      <P margin={[0, 0, 'M']}>
        {ENV.FF_TWO_TOGETHER_ENABLED
          ? `Clicking "Confirm" will create a Funeral Plan lead for ${config?.secondaryCoveredIndividualName}.`
          : 'Clicking "Confirm" will create a Funeral Plan lead for the customer’s partner.'}
      </P>
      <StyledWrapper>
        <Button.Primary type="button" onClick={handleConfirmClick}>
          Confirm
        </Button.Primary>
        <Button.Secondary
          type="button"
          onClick={() => store.dispatch(hideModal())}
        >
          Cancel
        </Button.Secondary>
      </StyledWrapper>
    </Wrapper>
  )
}

export default CouplePlanModal
