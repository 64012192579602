import { Form, Formik } from 'formik'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, Grid, Input } from '@farewill/ui'

import Error from 'components/form/error'
import { RadioGroupYesNoField } from 'components/form/radio-group-yes-no'
import apiRequest from 'lib/axios/api-request'
import { CREATE_PARTNER_ACCOUNT_SCHEMA } from 'lib/formik/schemata'
import { hideModal } from 'state/actions'

import {
  PARTNER_ACCOUNT_PREFERENCES_FIELDS,
  PARTNER_DATA_SHARING_REPORTS_READ_CONFIG,
  WEEKLY_PLEDGE_EMAIL_CONFIG,
} from './constants'
import {
  CreatePartnerAccountModalConfig,
  CreatePartnerAccountForm,
} from './types'

export const AddAccountModal = (config: {
  config: CreatePartnerAccountModalConfig
}) => {
  const { partnerId, isPartnerDataSharingEnabledPartner } = config.config
  const dispatch = useDispatch()
  const history = useHistory()

  const initialValues = {
    email: '',
    weeklyPledgeEmail: '',
    partnerDataSharingReports: isPartnerDataSharingEnabledPartner
      ? ''
      : 'false',
  } as CreatePartnerAccountForm

  const handleSubmit = async (values: CreatePartnerAccountForm) => {
    const { email, weeklyPledgeEmail } = values

    const subscriptions = []
    const access = []

    if (weeklyPledgeEmail === 'true') {
      subscriptions.push(WEEKLY_PLEDGE_EMAIL_CONFIG)
    }

    if (
      isPartnerDataSharingEnabledPartner &&
      values.partnerDataSharingReports === 'true'
    ) {
      access.push(PARTNER_DATA_SHARING_REPORTS_READ_CONFIG)
    }

    try {
      await apiRequest({
        url: `/api/partner-accounts`,
        method: 'PUT',
        data: {
          email,
          appMetadata: { partnerId },
          subscriptions,
          access,
        },
      })
      dispatch(hideModal())
      history.go(0) // Refresh page to re-retrieve accounts after add
    } catch (err) {
      console.error(`Adding email ${values.email}`, err)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => handleSubmit(values)}
      validationSchema={CREATE_PARTNER_ACCOUNT_SCHEMA}
    >
      {({ values, errors, isSubmitting }) => {
        return (
          <Form>
            <Grid>
              <Grid.Item>
                <Input
                  label="Email address"
                  name="email"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    values.email = e.target.value
                  }}
                />
                {errors.email && <Error name="email" />}
              </Grid.Item>
              <Grid.Item>
                <RadioGroupYesNoField
                  values={values}
                  errors={errors}
                  label={
                    PARTNER_ACCOUNT_PREFERENCES_FIELDS.WEEKLY_PLEDGE_EMAIL.label
                  }
                  name={
                    PARTNER_ACCOUNT_PREFERENCES_FIELDS.WEEKLY_PLEDGE_EMAIL.name
                  }
                />
              </Grid.Item>
              {isPartnerDataSharingEnabledPartner && (
                <Grid.Item>
                  <RadioGroupYesNoField
                    values={values}
                    errors={errors}
                    label={
                      PARTNER_ACCOUNT_PREFERENCES_FIELDS
                        .PARTNER_DATA_SHARING_REPORTS.label
                    }
                    name={
                      PARTNER_ACCOUNT_PREFERENCES_FIELDS
                        .PARTNER_DATA_SHARING_REPORTS.name
                    }
                  />
                </Grid.Item>
              )}
              <Grid.Item>
                <Button.Primary type="submit" stretch loading={isSubmitting}>
                  Add user
                </Button.Primary>
              </Grid.Item>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}
