import { ReactElement, useEffect } from 'react'
import { useFormikContext } from 'formik'
import { Grid, H } from '@farewill/ui'

import { FuneralPlanLeadAttributes } from 'lib/models/lead'
import RadioGroup from 'components/form/radio-group'
import { AddressInput, Input } from 'components/form'

const FORM_FIELDS_NAMES = {
  BUYING_FOR_THEMSELVES: 'purchaserIsCoveredIndividual',
  COVERED_INDIVIDUAL_CONTACT: 'coveredIndividual',
  NOMINATED_REPRESENTATIVE: 'hasNominatedRepresentative',
  NOMINATED_REPRESENTATIVE_CONTACT: 'nominatedRepresentative',
  NOMINATED_REPRESENTATIVE_CALLER: 'purchaserIsNominatedRepresentative',
} as const

const NominatedRepresentativeContactDetails = (): ReactElement => {
  const { values, handleSubmit, setFieldValue } =
    useFormikContext<FuneralPlanLeadAttributes>()

  useEffect(() => {
    if (
      values.quoteAttributes.nominatedRepresentative?.canBeContactedDirectly ===
      'false'
    ) {
      setFieldValue(
        `quoteAttributes.${FORM_FIELDS_NAMES.NOMINATED_REPRESENTATIVE_CONTACT}.addressFields`,
        {
          lineOne: null,
          lineTwo: null,
          city: null,
          countryCode: null,
          postalCode: null,
        }
      )
      handleSubmit()
    }
  }, [
    handleSubmit,
    setFieldValue,
    values.quoteAttributes.nominatedRepresentative?.canBeContactedDirectly,
  ])

  return (
    <Grid.Item>
      <Grid style={{ gridAutoFlow: 'row' }}>
        <Grid.Item spanFromL={6} startColumn={1}>
          <Input
            label="First name"
            name={`quoteAttributes.${FORM_FIELDS_NAMES.NOMINATED_REPRESENTATIVE_CONTACT}.firstName`}
            handleSave={() => handleSubmit()}
            allowCopyToClipboard
          />
        </Grid.Item>
        <Grid.Item spanFromL={6}>
          <Input
            label="Last name"
            name={`quoteAttributes.${FORM_FIELDS_NAMES.NOMINATED_REPRESENTATIVE_CONTACT}.lastName`}
            handleSave={() => handleSubmit()}
            allowCopyToClipboard
          />
        </Grid.Item>
        <Grid.Item spanFromL={6}>
          <Input
            label="Phone number"
            name={`quoteAttributes.${FORM_FIELDS_NAMES.NOMINATED_REPRESENTATIVE_CONTACT}.phoneNumber`}
            handleSave={() => handleSubmit()}
            allowCopyToClipboard
          />
        </Grid.Item>
        <Grid.Item spanFromL={6}>
          <Input
            name={`quoteAttributes.${FORM_FIELDS_NAMES.NOMINATED_REPRESENTATIVE_CONTACT}.email`}
            label="Email"
            type="email"
            handleSave={() => handleSubmit()}
            allowCopyToClipboard
          />
        </Grid.Item>
        <Grid.Item>
          <RadioGroup
            label="Can we contact this person directly?"
            name={`quoteAttributes.${FORM_FIELDS_NAMES.NOMINATED_REPRESENTATIVE_CONTACT}.canBeContactedDirectly`}
            options={[
              { value: 'true', label: 'Yes' },
              { value: 'false', label: 'No' },
            ]}
            handleSave={() => handleSubmit()}
          />
        </Grid.Item>
        {values.quoteAttributes.nominatedRepresentative
          ?.canBeContactedDirectly === 'true' && (
          <Grid.Item spanFromL={12}>
            <AddressInput
              label="Address"
              name={`quoteAttributes.${FORM_FIELDS_NAMES.NOMINATED_REPRESENTATIVE_CONTACT}`}
              onAddressChanged={(args) => {
                setFieldValue(
                  `quoteAttributes.${FORM_FIELDS_NAMES.NOMINATED_REPRESENTATIVE_CONTACT}.addressFields`,
                  args
                )
                handleSubmit()
              }}
              addressValues={
                values.quoteAttributes[
                  FORM_FIELDS_NAMES.NOMINATED_REPRESENTATIVE_CONTACT
                ]?.addressFields
              }
            />
          </Grid.Item>
        )}
      </Grid>
    </Grid.Item>
  )
}

const NominatedRepresentative = ({
  dataTestid,
}: {
  dataTestid: string
}): ReactElement => {
  const { values, handleSubmit, setFieldValue } =
    useFormikContext<FuneralPlanLeadAttributes>()

  const purchaserIsCoveredIndividual =
    values.quoteAttributes[FORM_FIELDS_NAMES.BUYING_FOR_THEMSELVES] === 'true'
  const wantsNominatedRepresentative =
    values.quoteAttributes[FORM_FIELDS_NAMES.NOMINATED_REPRESENTATIVE] ===
    'true'
  const callerIsNotNominatedRepresentative =
    values.quoteAttributes[
      FORM_FIELDS_NAMES.NOMINATED_REPRESENTATIVE_CALLER
    ] === 'false'

  /**
   * If the answer for "Do they want a nominated representative?" is changing from Yes to No,
   * we need to clear any added nominated representative contact details.
   * And if the caller is the nominated representative, we also need to clear the
   * purchaserIsNominatedRepresentative field.
   */
  const handleWantsNominatedRepresentativeChange = ({
    value,
  }: {
    value: string
  }) => {
    if (value === 'false') {
      const emptyNominatedRepresentative = {
        firstName: null,
        lastName: null,
        phoneNumber: null,
        email: null,
        addressFields: {
          addressLine1: null,
          addressLine2: null,
          addressTown: null,
          addressCounty: null,
          addressPostcode: null,
        },
        canBeContactedDirectly: null,
      }
      setFieldValue(
        `quoteAttributes.${FORM_FIELDS_NAMES.NOMINATED_REPRESENTATIVE_CONTACT}`,
        emptyNominatedRepresentative
      )
      !callerIsNotNominatedRepresentative &&
        setFieldValue(
          `quoteAttributes.${FORM_FIELDS_NAMES.NOMINATED_REPRESENTATIVE_CALLER}`,
          null
        )
    }
    handleSubmit()
  }

  return (
    <Grid.Item data-testId={dataTestid}>
      <H size="S" margin={['XL', 0, 'S']}>
        Nominated representative
      </H>
      <Grid data-testid="nominated-rep">
        <Grid.Item>
          <RadioGroup
            name={`quoteAttributes.${FORM_FIELDS_NAMES.NOMINATED_REPRESENTATIVE}`}
            label="Do they want a nominated representative?"
            handleSave={handleWantsNominatedRepresentativeChange}
            options={[
              { label: 'Yes', value: 'true' },
              {
                label: 'No',
                value: 'false',
              },
            ]}
          />
        </Grid.Item>
        {wantsNominatedRepresentative &&
          (purchaserIsCoveredIndividual ? (
            <NominatedRepresentativeContactDetails />
          ) : (
            <>
              <Grid.Item>
                <RadioGroup
                  name={`quoteAttributes.${FORM_FIELDS_NAMES.NOMINATED_REPRESENTATIVE_CALLER}`}
                  label="Is the caller the nominated representative?"
                  options={[
                    { label: 'Yes', value: 'true' },
                    {
                      label: 'No',
                      value: 'false',
                    },
                  ]}
                  handleSave={() => handleSubmit()}
                />
              </Grid.Item>
              {callerIsNotNominatedRepresentative && (
                <NominatedRepresentativeContactDetails />
              )}
            </>
          ))}
      </Grid>
    </Grid.Item>
  )
}

export default NominatedRepresentative
