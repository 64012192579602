import { Link } from 'react-router-dom'
import { Grid, H } from '@farewill/ui'

import ENV from 'config/environment'
import { isFuneralPlanLead } from 'lib/types/funeral-plans'
import { useLeadContext } from '../context'
import CoveredIndividualContactData from './covered-individual-contact-data'
import NominatedRepresentative from './nominated-representative'
import QualifyingQuestions from './qualifying-questions'
import RaisingMoneyForCharity from './raising-money-for-charity'

const CoveredIndividual = ({
  sectionNumber,
}: {
  sectionNumber: 1 | 2
}): React.ReactElement => {
  const { lead } = useLeadContext()
  const { attributes } = lead
  if (!isFuneralPlanLead(attributes)) {
    return <> </>
  }

  const { planType, coveredIndividualNumber, coupledLeadId } =
    attributes.quoteAttributes

  const hideFields =
    planType === 'two_together' &&
    coveredIndividualNumber &&
    coveredIndividualNumber !== sectionNumber

  if (hideFields) {
    return (
      <>
        <H size="S">{`Covered individual ${sectionNumber}`}</H>
        <Grid style={{ gridAutoFlow: 'row' }}>
          <Grid.Item spanFromL={12} startColumn={1}>
            <span>Because the customer is paying in instalments,</span>{' '}
            <Link to={`/leads/${coupledLeadId}`}>
              {`view covered individual ${sectionNumber} in a separate lead.`}
            </Link>
          </Grid.Item>
        </Grid>
      </>
    )
  }

  return sectionNumber === 2 ? (
    <>
      <CoveredIndividualContactData
        dataTestid="secondary-covered-individual"
        coveredIndividualSection={2}
      />
      {/* TODO: Add these component for the secondary covered individual */}
      {/* <QualifyingQuestions /> */}
      {/* nom rep section needs to be secondary nom rep format  */}
      {/* <NominatedRepresentative dataTestid="secondary-nominated-representative" /> */}
    </>
  ) : (
    <>
      <CoveredIndividualContactData
        dataTestid="covered-individual"
        coveredIndividualSection={planType === 'two_together' ? 1 : undefined}
      />
      <QualifyingQuestions />
      <NominatedRepresentative dataTestid="nominated-representative" />
      {ENV.FF_FUNERAL_PLANS_PARTNERSHIPS_ENABLED && (
        <RaisingMoneyForCharity dataTestid="raising-money-for-charity" />
      )}
    </>
  )
}

export default CoveredIndividual
