import { ReactElement } from 'react'
import { useFormikContext } from 'formik'
import { Grid, H } from '@farewill/ui'

import { FuneralPlanLeadAttributes } from 'lib/models/lead'
import { CircularRadioGroup, Input } from 'components/form'
import { getYearsElapsed } from 'utils/helpers'

import { useLeadContext } from '../context'

const CoveredIndividualContactData = ({
  dataTestid,
  coveredIndividualSection,
}: {
  dataTestid: string
  coveredIndividualSection?: 1 | 2
}): ReactElement => {
  const { values, handleSubmit } = useFormikContext<FuneralPlanLeadAttributes>()
  const { updateContact } = useLeadContext()

  /** The quoteAttributes.purchaserIsCoveredIndividual value only represents if the purchaser 
  is the first coveredIndividual. So, if we are rendering covered individual section 2, 
  we need to set purchaserIsCoveredIndividual to false. */
  const purchaserIsCoveredIndividual =
    values.quoteAttributes.purchaserIsCoveredIndividual === 'true' &&
    coveredIndividualSection !== 2

  const hashKey =
    coveredIndividualSection === 2
      ? 'secondaryCoveredIndividual'
      : 'coveredIndividual'

  /** if purchaser is covered individual, the date of birth is stored on the purchaser
   * contact rather than in the covered individual object on quoteAttributes. */
  const coveredIndividualDateOfBirth = purchaserIsCoveredIndividual
    ? values.contact?.dateOfBirth
    : values.quoteAttributes[hashKey]?.dateOfBirth

  return (
    <Grid.Item data-testId={dataTestid}>
      <>
        <H size="S">
          {`Covered individual${
            coveredIndividualSection
              ? ` ${coveredIndividualSection}`
              : '’s details'
          }`}
        </H>
        <Grid>
          {!purchaserIsCoveredIndividual && (
            <>
              <Grid.Item spanFromL={6} startColumn={1}>
                <Input
                  label="First name"
                  name={`quoteAttributes.${hashKey}.firstName`}
                  handleSave={() => handleSubmit()}
                  allowCopyToClipboard
                />
              </Grid.Item>
              <Grid.Item spanFromL={6}>
                <Input
                  label="Last name"
                  name={`quoteAttributes.${hashKey}.lastName`}
                  handleSave={() => handleSubmit()}
                  allowCopyToClipboard
                />
              </Grid.Item>
            </>
          )}
          <Grid.Item spanFromL={6}>
            <Input
              type="date"
              label="Date of birth"
              name={
                purchaserIsCoveredIndividual
                  ? 'contact.dateOfBirth'
                  : `quoteAttributes.${hashKey}.dateOfBirth`
              }
              handleSave={
                purchaserIsCoveredIndividual
                  ? updateContact
                  : () => handleSubmit()
              }
              hint={
                coveredIndividualDateOfBirth &&
                `Age: ${getYearsElapsed({
                  dateOfBirth: coveredIndividualDateOfBirth,
                })}`
              }
            />
          </Grid.Item>
          <Grid.Item>
            <CircularRadioGroup
              label="Sex"
              name={`quoteAttributes.${hashKey}.sex`}
              inline
              options={[
                { label: 'Male', value: 'male' },
                { label: 'Female', value: 'female' },
              ]}
              handleSave={() => handleSubmit()}
            />
          </Grid.Item>
        </Grid>
      </>
    </Grid.Item>
  )
}

export default CoveredIndividualContactData
