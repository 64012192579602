import { useFormikContext } from 'formik'
import { ReactElement, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Grid, H, P, Wrapper } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'

import MessageBox from 'components/message-box'
import useApi from 'lib/effects/api'
import { LeadAttributes } from 'lib/models/lead'
import { isFuneralPlanLead } from 'lib/types/funeral-plans'
import { showModal } from 'state/actions'
import store from 'state/create-store'
import { FAREWILL_PRODUCTS } from 'utils/enums'

import { useLeadContext } from '../context'
import CouplePlanModal from './couple-plan-modal'

const TwoTogetherInstalmentsLeadCreation = (): ReactElement => {
  const { lead, fetchLead } = useLeadContext()
  const { setFieldValue } = useFormikContext<LeadAttributes>()

  const [, callApi] = useApi()

  const [showLeadCreationConfirmationBox, setShowLeadCreationConfirmationBox] =
    useState(false)

  const leadAttributes = lead.attributes

  if (!isFuneralPlanLead(leadAttributes)) {
    return <></>
  }

  const {
    contact,
    partnerId,
    partnerType,
    sourceIdentifier,
    sourceType,
    quoteAttributes: {
      coupledLeadId,
      heardAboutFarewillVia,
      isPostalService,
      vulnerabilities,
      paymentType,
      secondaryCoveredIndividual,
    },
  } = leadAttributes

  const confirmCreateSecondLead = async () => {
    await callApi({
      url: `/api/leads`,
      method: 'POST',
      data: {
        data: {
          type: 'leads',
          attributes: {
            product: FAREWILL_PRODUCTS.FUNERAL_PLAN,
            /** populate the second lead with the same shared attributes and the 
            secondaryCoveredIndividual */
            contactId: contact.id,
            partnerId,
            partnerType: partnerType ?? 'none',
            sourceIdentifier,
            sourceType,
            quoteAttributes: {
              planType: 'two_together',
              paymentType,
              purchaserIsCoveredIndividual: false,
              heardAboutFarewillVia,
              isPostalService,
              vulnerabilities,
              coupledLeadId: lead.id,
              secondaryCoveredIndividual,
              coveredIndividualNumber: 2,
            },
          },
        },
      },
    })

    /** Remove the secondaryCoveredIndividual data from the first lead and set the
     * coveredIndividualNumber so that we can clearly identify which is the first
     * and which the second lead
     */
    await callApi({
      url: `/api/leads/${lead.id}`,
      method: 'PATCH',
      data: {
        data: {
          type: 'leads',
          id: lead.id,
          attributes: {
            quoteAttributes: {
              secondaryCoveredIndividual: {
                firstName: null,
                lastName: null,
                dateOfBirth: null,
                sex: null,
              },
              coveredIndividualNumber: 1,
            },
          },
        },
      },
    })

    setFieldValue('quoteAttributes.secondaryCoveredIndividual.firstName', '')
    setFieldValue('quoteAttributes.secondaryCoveredIndividual.lastName', '')
    setFieldValue('quoteAttributes.secondaryCoveredIndividual.dateOfBirth', '')
    setFieldValue('quoteAttributes.secondaryCovered.individual.sex', '')

    setShowLeadCreationConfirmationBox(true)

    return fetchLead()
  }

  const handleCreateCoupledLead = async () => {
    store.dispatch(
      showModal({
        component: CouplePlanModal,
        headingText: 'Create new lead',
        config: {
          confirmCreateLead: confirmCreateSecondLead,
          secondaryCoveredIndividualName,
        },
      })
    )
  }

  const secondaryCoveredIndividualName =
    secondaryCoveredIndividual?.firstName &&
    secondaryCoveredIndividual?.lastName
      ? `${secondaryCoveredIndividual?.firstName} ${secondaryCoveredIndividual?.lastName}`
      : undefined

  return (
    <>
      <Grid.Item>
        {!coupledLeadId && (
          <Grid.Item>
            <P>
              To pay for both plans in instalments, you need to create a new
              lead for Covered individual 2. Because they are 2 separate plans,
              2 payments will be taken per month. Make sure the customer
              understands this before creating a new lead and taking payment.
            </P>
            <P>Take each payment separately.</P>
            <Button.Primary onClick={handleCreateCoupledLead}>
              Create new lead for Covered individual 2
            </Button.Primary>
          </Grid.Item>
        )}
        {showLeadCreationConfirmationBox && (
          <MessageBox success>
            <H size="XS" color={COLOR.GREY.DARK}>
              A new lead has been created for Covered individual 2
            </H>
            <P margin={0}>Next steps:</P>
            <Wrapper padding={[0, 0, 'S', 'L']}>
              <P>
                <ul style={{ listStyleType: 'disc' }}>
                  <li>
                    Set up instalments for covered individual 1 on this page
                  </li>
                  <li>
                    Then set up instalments for{' '}
                    <Link
                      to={`/leads/${coupledLeadId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      covered individual 2
                    </Link>
                  </li>
                </ul>
              </P>
            </Wrapper>
          </MessageBox>
        )}
      </Grid.Item>
    </>
  )
}

export default TwoTogetherInstalmentsLeadCreation
