import { getStore } from 'lib/storage/sessionStorage'

const ENV = {
  ACCOUNT_APP_URL: process.env.REACT_APP_ACCOUNT_APP_URL,
  APP_ENV: process.env.REACT_APP_APP_ENV,
  CONTENTFUL_ENV_ID: process.env.REACT_APP_CONTENTFUL_ENV_ID,
  CONTENTFUL_SPACE_ID: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
  GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  LOGO_ICONS: process.env.REACT_APP_LOGO_ICONS,
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  SQUARE_DASHBOARD_URL: process.env.REACT_APP_SQUARE_DASHBOARD_URL,
  STRIPE_DASHBOARD_URL: process.env.REACT_APP_STRIPE_DASHBOARD_URL,
  FF_LPAS_ENABLED: JSON.parse(process.env.REACT_APP_FF_LPAS_ENABLED),
  FF_AI_NOTES_ENABLED: JSON.parse(process.env.REACT_APP_FF_AI_NOTES_ENABLED),
  FF_LIMIT_TRUST_LEADS_TO_WILL_WRITERS_ENABLED: JSON.parse(
    process.env.REACT_APP_FF_LIMIT_TRUST_LEADS_TO_WILL_WRITERS_ENABLED
  ),
  FF_ROLE_BASED_AUTHENTICATION_ENABLED: JSON.parse(
    process.env.REACT_APP_FF_ROLE_BASED_AUTHENTICATION_ENABLED
  ),
  FF_WILL_CALLBACK_ANYTIME_ENABLED: JSON.parse(
    process.env.REACT_APP_FF_WILL_CALLBACK_ANYTIME_ENABLED
  ),
  FF_WILLSUITE_ENABLED: JSON.parse(process.env.REACT_APP_FF_WILLSUITE_ENABLED),
  FF_WILLS_BOOKED_CALL_EMAILS_ENABLED: JSON.parse(
    process.env.REACT_APP_FF_WILLS_BOOKED_CALL_EMAILS_ENABLED
  ),
  FF_TWO_TOGETHER_ENABLED: JSON.parse(
    process.env.REACT_APP_FF_TWO_TOGETHER_ENABLED
  ),
  FF_TELEPHONE_WILL_CANCELLATION_FEE_ENABLED: JSON.parse(
    process.env.REACT_APP_TELEPHONE_WILL_CANCELLATION_FEE_ENABLED
  ),
  FF_FUNERAL_PLANS_PARTNERSHIPS_ENABLED: JSON.parse(
    process.env.REACT_APP_FF_FUNERAL_PLANS_PARTNERSHIPS_ENABLED
  ),
  FF_CREMATION_FORM_1_2024_ENABLED: JSON.parse(
    process.env.REACT_APP_FF_CREMATION_FORM_1_2024_ENABLED
  ),
  TELEPHONE_WILL_CANCELLATION_FEE_IN_PENCE: JSON.parse(
    process.env.REACT_APP_TELEPHONE_WILL_CANCELLATION_FEE_IN_PENCE
  ),
  WILLSUITE_PLATFORM_URL: process.env.REACT_APP_WILLSUITE_PLATFORM_URL,
}

const proxy = new Proxy(ENV, {
  get(target, prop) {
    const isFlag = prop.startsWith('FF_')

    if (!isFlag) {
      return target[prop]
    }

    const override = getStore(prop)
    if (override !== null) {
      // eslint-disable-next-line no-console
      console.debug(`[ENV] using override for flag ${prop}: ${override}`)
      return JSON.parse(override)
    }

    // Check for override.
    return target[prop]
  },
})

export default proxy
