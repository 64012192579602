import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Formik } from 'formik'
import { Divider, Grid, Wrapper } from '@farewill/ui'

import MessageBox from 'components/message-box'
import ENV from 'config/environment'
import useApi from 'lib/effects/api'
import { QuoteResponseDataFuneralPlan } from 'lib/types'
import { isFuneralPlanLead } from 'lib/types/funeral-plans'
import { formatValuesForFormik } from 'utils/helpers'

import { useLeadContext } from '../context'
import QuoteForm from '../quote-form'

import Brochure from './brochure'
import ContactDetails from './contact-details'
import CouplePlan from './couple-plan'
import CoveredIndividual from './covered-individual'
import PaymentQuestions from './payment-questions'
import Plan from './plan'
import VulnerabilityCheck from './vulnerability-check'
import Vulnerabilities from './vulnerabilities'

type Props = {
  disabled: boolean
}

const FuneralPlanForm = ({ disabled }: Props): React.ReactElement => {
  const { onSubmit, lead } = useLeadContext()
  const [{ data: quote }, makeRequest] = useApi<QuoteResponseDataFuneralPlan>()

  useEffect(() => {
    if (!isFuneralPlanLead(lead.attributes)) {
      return
    }

    if (lead.id && lead.attributes.quoteAttributes.paymentType) {
      makeRequest({
        url: '/api/quotes',
        method: 'POST',
        data: {
          data: {
            type: 'quotes',
            attributes: {
              product: 'funeral_plan',
              leadId: lead.id,
            },
          },
        },
      })
    }
  }, [lead, lead.attributes, makeRequest])

  if (!isFuneralPlanLead(lead.attributes)) {
    return <></>
  }

  const coupledLeadId = lead.attributes.quoteAttributes?.coupledLeadId

  const planType = lead.attributes.quoteAttributes.planType

  return (
    <Formik
      /** Passing the lead id as a key forces React to remount the component. This was needed
       * because when clicking 'View linked lead' from the first to the second lead, the form
       * was not resetting with the data of the 2nd lead.  */
      key={lead.id}
      initialValues={formatValuesForFormik(lead.attributes)}
      onSubmit={onSubmit}
    >
      <QuoteForm disabled={disabled}>
        {coupledLeadId && ENV.FF_TWO_TOGETHER_ENABLED && (
          <Grid.Item margin={[0, 0, 'M']}>
            <MessageBox>
              This is 1 of 2 leads for a two-together plan in instalments.{' '}
              <Link to={`/leads/${coupledLeadId}`}>View linked lead.</Link>
            </MessageBox>
          </Grid.Item>
        )}
        <ContactDetails />
        <Divider margin={['L', 0]} />
        <Brochure />
        <Divider margin={['L', 0]} />
        <Vulnerabilities />
        <Divider margin={['L', 0]} />
        {!ENV.FF_TWO_TOGETHER_ENABLED && (
          <>
            <CouplePlan />
            <Divider margin={['L', 0]} />
          </>
        )}
        <Plan />
        <Divider margin={['L', 0]} />
        {planType === 'two_together' ? (
          <>
            <CoveredIndividual sectionNumber={1} />
            <CoveredIndividual sectionNumber={2} />
          </>
        ) : (
          <CoveredIndividual sectionNumber={1} />
        )}
        <Divider margin={['L', 0]} />
        <Wrapper margin={['L', 0]}>
          <VulnerabilityCheck />
        </Wrapper>
        <Divider margin={['L', 0]} />
        <PaymentQuestions
          quote={quote?.attributes}
          reference={lead.attributes.funeralPlanReference || ''}
          lead={lead.attributes}
        />
      </QuoteForm>
    </Formik>
  )
}

export default FuneralPlanForm
